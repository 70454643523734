<template>
	<v-expansion-panels class="course__nav">
		<v-expansion-panel>
			<v-expansion-panel-header class="primary-contrast-background"
				><h2 class="course__nav--title">
					All Chapters
				</h2></v-expansion-panel-header
			>
			<v-expansion-panel-content>
				<nav>
					<v-container>
						<v-row>
							<ul class="col-3">
								<li>
									<h3 class="course__nav--chapter-title">
										<router-link
											to="/student/course?course=3"
											>Intro</router-link
										>
									</h3>
								</li>
							</ul>
							<ul
								class="col-3"
								v-for="chapter in chapters"
								:key="chapter.id"
							>
								<li>
									<h3 class="course__nav--chapter-title">
										{{ chapter.title }}
									</h3>
								</li>
								<ul>
									<li
										class="course__nav--item"
										v-for="page in chapter.pages"
										:key="page.id"
										@click="
											setActivePage(
												page.chapter_id,
												page.id
											)
										"
									>
										{{ page.title }}
									</li>
								</ul>
							</ul>
						</v-row>
					</v-container>
				</nav>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	name: "CourseChapters",
	components: {},
	props: {
		chapters: {
			type: Array,
			require: true
		}
	},
	methods: {
		setActivePage(chapterId, pageId) {
			if (
				Number(this.$route.query.chapter) === Number(chapterId) &&
				Number(this.$route.query.page) === Number(pageId)
			)
				return;
			localStorage.setItem(
				`courseData[${this.$route.query.course}]`,
				JSON.stringify({
					chapterId,
					pageId
				})
			);
			this.$router.push({
				query: {
					...this.$route.query,
					chapter: chapterId,
					page: pageId
				}
			});
		}
	}
};
</script>

<style></style>
